import request from '@/utils/request'
import qs from 'qs';

   /**
 * 获取在线阅读链接
 */
 export function getread_url(data) {
    return request({
      url: 'literature/get_full_text/',
      method: 'post',
      data
    })
  }

  /**
 * 获取总页数
 */
// export function getpagetotal(data) {
//     return request({
//       url: 'literature/get_full_text/',
//       method: 'get',
//       params:data
//     })
//   }
  //获取图书传递记录
  export function getbooktransmit(data) {
    return request({
      url: 'literature/chinese_book_transmit/',
      method: 'get',
      params: data
    })
  }
  
   /**
 * 获取pdf内容
 */
export function getpdf(data) {
    return request({
      url: 'literature/chinese_book_transmit/',
      method: 'post',
      data
    })
  }
  //阅读链接查询青梨
  export function searchtaskid(data) {
    return request({
      url: 'literature/read_url_search_taskid/',
      method: 'post',
      data: data
    })
  }
    //获取图书传递记录
    export function getbooktransmitdetail(data) {
      return request({
        url: 'literature/get_user_book_transmit_detail/',
        method: 'get',
        params: data
      })
    }
    
